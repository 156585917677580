


/* router tests */
function testPageInfoTraficNoHash(location){
    var regex = /.*\/infos_trafic\/$/;
    var pageInfoTrafic = regex.test(location.pathname);
    var hash = location.hash; var strHash = hash.substring(1);
    return (pageInfoTrafic && !strHash);
}

function testPageNotInfoTrafic(location){
    var regex = /.*\/infos_trafic\/$/;
    var pageNotInfoTrafic =  (!regex.test(location.pathname));
    return (pageNotInfoTrafic);
}

function testPageInfoTraficHash(location){
    var regex = /.*\/infos_trafic\/$/;
    var pageInfoTrafic =  regex.test(location.pathname);
    var hash = location.hash; var strHash = hash.substring(1);
    return (pageInfoTrafic && strHash);
}


function testAdd(x,y){ return x+y }

function computeTitleUrl(location, alert){
    return `${location.pathname}infos_trafic/`;
}

function computeAlertUrl(location,alert){
    var regex = /.*\/infos_trafic\/$/;
    var pageInfoTrafic =  regex.test(location.pathname);   
    if (pageInfoTrafic) return `#${alert.getId()}`;
    if (!pageInfoTrafic) return `${location.pathname}infos_trafic/#${alert.getId()}`
}





/* config */ 

export var config = {
    alertProviders : [
         {
             provider : 'urbiplan',
             name : 'urbiplan-api-prod',
             config : {
                 instance : 'belfort',
                 env : 'prod',
                 key : '76350b70682e051e6709782b551f5437173f1957',
                 preferredLang : 'fr'
             }
         },
         {
             provider : 'urbiplan',
             name : 'urbiplan-api-preprod',
             config : {
                   instance: 'belfort',
                   env : 'preprod',
                   key : '134c234c03617029696b311409307f470c7f4003',
                   preferredLang : 'fr'
             }
         }
     ],
    
     defaultAlertProviderConfigName : 'urbiplan-api-prod',
     
     routerConfigs :[ {
            test : testPageNotInfoTrafic.toString(),
            viewConfigName : 'main1View1Config'
        },{
            test : testPageInfoTraficNoHash.toString(),
            viewConfigName : 'main1View2Config'
        },{
            test : testPageInfoTraficHash.toString(),
            viewConfigName : 'main1View3Config'
        }],

    href : {
        computeTitleUrl :  computeTitleUrl.toString(),
        computeAlertTitleUrl : computeAlertUrl.toString() // 'infos_trafic/#{id}"  or '#{id}' depending where it is 
    },
    
    options : {
        hasSelector : false,
        alertSorterType : 'publicationdate'  // [level, publicationdate]
    }    
}




