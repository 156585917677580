var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../customers/belfort/widget-alerts/node_modules/@mecatran-projects/widget-alerts/src/nunjucks-extensions/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});




var shim = require("A:/dev/workspace-node/bitbucket_repository/projects/customers/belfort/widget-alerts/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["../../../widget-alerts/src/templates/icons.njk"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
var macro_t_1 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_2 = "";t_2 += "\r\n";
var t_3;
t_3 = "#355880";
frame.set("color", t_3, true);
if(frame.topLevel) {
context.setVariable("color", t_3);
}
if(frame.topLevel) {
context.addExport("color", t_3);
}
t_2 += "\r\n<svg width=\"32\" height=\"32\" viewBox=\"0 0 16 16\" class=\"bi bi-info-circle-fill\" fill=\"";
t_2 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "color"), env.opts.autoescape);
t_2 += "\" xmlns=\"http://www.w3.org/2000/svg\">\r\n  <path fill-rule=\"evenodd\" d=\"M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z\"/>\r\n</svg>\r\n";
;
frame = callerFrame;
return new runtime.SafeString(t_2);
});
context.addExport("info");
context.setVariable("info", macro_t_1);
output += "\r\n\r\n";
var macro_t_4 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_5 = "";t_5 += "\r\n";
var t_6;
t_6 = "#FFA500";
frame.set("color", t_6, true);
if(frame.topLevel) {
context.setVariable("color", t_6);
}
if(frame.topLevel) {
context.addExport("color", t_6);
}
t_5 += "\r\n<svg width=\"32\" height=\"32\" viewBox=\"0 0 17 16\" class=\"bi bi-exclamation-triangle-fill\" fill=\"";
t_5 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "color"), env.opts.autoescape);
t_5 += "\" xmlns=\"http://www.w3.org/2000/svg\">\r\n  <path fill-rule=\"evenodd\" d=\"M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z\"/>\r\n</svg>\r\n";
;
frame = callerFrame;
return new runtime.SafeString(t_5);
});
context.addExport("warning");
context.setVariable("warning", macro_t_4);
output += "\r\n\r\n";
var macro_t_7 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_8 = "";t_8 += "\r\n";
var t_9;
t_9 = "#A91B0D";
frame.set("color", t_9, true);
if(frame.topLevel) {
context.setVariable("color", t_9);
}
if(frame.topLevel) {
context.addExport("color", t_9);
}
t_8 += "\r\n<svg width=\"32\" height=\"32\" viewBox=\"0 0 16 16\" class=\"bi bi-x-circle-fill\" fill=\"";
t_8 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "color"), env.opts.autoescape);
t_8 += "\" xmlns=\"http://www.w3.org/2000/svg\">\r\n  <path fill-rule=\"evenodd\" d=\"M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z\"/>\r\n</svg>\r\n";
;
frame = callerFrame;
return new runtime.SafeString(t_8);
});
context.addExport("critical");
context.setVariable("critical", macro_t_7);
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["../../../widget-alerts/src/templates/icons.njk"] , dependencies)