
export class UtilsPromise{
    constructor(){}
    static  wait(...promises){
        return Promise.all(promises);
    }


    static split(...promises){
        return (res,rej)=>{
            promises.forEach(promise => promise(res,rej));
        }
    }

    static save(container){
        return (res,rej)=>{
            container(res,rej);
            if (res) return res;
            else return rej;
        }
    }

    /*
    static wrapper(handler){ // .then(wrapper(handler))  <==> .then((val)=>{handler(val)})
        return (res,rej)=>{
            return handler(res,rej)
        }
    }*/

    static wrapper(object){
        return (res,rej)=>{
            if (res) return object;
            else return rej;
        }
    }


    static SyncAsync(object){ return Promise.resolve(func) }

}