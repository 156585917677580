var parseArgs = function(argv) {
  var parsed = {};
  var argv = argv || process.argv.slice(2);
  for (var i = 0; i < argv.length; i += 2) {
    var value = argv[i + 1];
    try {
      value = JSON.parse(value);
    } catch (e) {
    }
    parsed[argv[i].replace(/^-*/g, '')] = value;
  }
  return parsed;
};

var callback = function(error, data) {
  if (error) {
    console.log("Error:", error.message != undefined ? error.message : error);
  } else {
	  console.log(data);
    // console.log(JSON.stringify(data, null, 4));
  }
};

export var cli = {parseArgs, callback};