import {UtilsType} from './type.mjs';
import prettyjson from 'prettyjson';

export class UtilsPrint{
    static log(data,logOptions){
        var d = data; 
        if (d === undefined) return;
    
        if (UtilsType.isArray(data)){
            d = data.map(i => UtilsPrint.log(i,logOptions) || i);   // should be recursive, will not work with array of array (remove undefined)
        } else if (UtilsType.isObject(data)){
                if (data.log)  d = data.log(logOptions);
                else d = data;
        } 
        
        return prettyjson.render(d);
    }
    
    
    static print(data,logOptions){  
        console.log(UtilsPrint.log(data, logOptions))
        return data;
    }

    static logTable(data,logOptions= {mapOnKey : 'id'}){
        var d = data; 
        if (d === undefined) return;
        var ret; 
        if (UtilsType.isArray(d)){
            var ret = {}
            d.forEach(i =>{ 
                if (UtilsType.isUndef(i)) return;
                if (i.logTable) {
                    Object.assign(ret, i.logTable(logOptions))   // #.logTable should be rename .logAsObject ?
                } else {
                    var mapKey = logOptions.mapOnKey || 'id';
                    var identifier = i[mapKey];   // example stop['id'], or stop['code'] normally should access with stop.getId()
                    ret[identifier] = i;
                }
           })
        } else if (UtilsType.isObject(d)){
            ret = (d.logTable) ? d.logTable(logOptions) : ret;
        }
        return ret;
    }
    
    static printTable(data,logOptions){
        if (logOptions) console.table(UtilsPrint.logTable(data,logOptions));
        else console.table(UtilsPrint.logTable(data));
        return data;
    }

    /**
     * https://stackoverflow.com/questions/9781218/how-to-change-node-jss-console-font-color
     */
    static getColor(col){  
        var color = {
            reset : "\x1b[0m",
            bright : "\x1b[1m",
            dim : "\x1b[2m",
            underscore : "\x1b[4m",
            blink : "\x1b[5m",
            reverse : "\x1b[7m",
            hidden : "\x1b[8m",
            
            fgBlack : "\x1b[30m",
            fgRed : "\x1b[31m",
            fgGreen : "\x1b[32m",
            fgYellow : "\x1b[33m",
            fgBlue : "\x1b[34m",
            fgMagenta : "\x1b[35m",
            fgCyan : "\x1b[36m",
            fgWhite : "\x1b[37m",
            
            bgBlack : "\x1b[40m",
            bgRed : "\x1b[41m",
            bgGreen : "\x1b[42m",
            bgYellow : "\x1b[43m",
            bgBlue : "\x1b[44m",
            bgMagenta : "\x1b[45m",
            bgCyan : "\x1b[46m",
            bgWhite : "\x1b[47m",
        }
        return color[col];
    }
    static getTime(){
        var d = new Date();
        return `${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}:${d.getMilliseconds()}`;
    }
    static printTime(...arg){
        var str = arg.join('')
        console.log(UtilsPrint.getColor('fgRed'),UtilsPrint.getTime(),UtilsPrint.getColor('reset'),str);
    }
}