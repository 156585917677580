var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../customers/belfort/widget-alerts/node_modules/@mecatran-projects/widget-alerts/src/nunjucks-extensions/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["./macros.njk"] = require( "./macros.njk" );




var shim = require("A:/dev/workspace-node/bitbucket_repository/projects/customers/belfort/widget-alerts/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["../../../widget-alerts/src/templates/alertlist2.njk"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += "\r\n\r\n\r\n";
env.getTemplate("./macros.njk", false, "../../../widget-alerts/src/templates/alertlist2.njk", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("macros", t_1);
output += "\r\n\r\n";
var t_4;
t_4 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "alertListService")),"alertMacroName");
frame.set("alertMacroName", t_4, true);
if(frame.topLevel) {
context.setVariable("alertMacroName", t_4);
}
if(frame.topLevel) {
context.addExport("alertMacroName", t_4);
}
output += "\r\n";
var t_5;
t_5 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "alertListService")),"routeMacroName");
frame.set("routeMacroName", t_5, true);
if(frame.topLevel) {
context.setVariable("routeMacroName", t_5);
}
if(frame.topLevel) {
context.addExport("routeMacroName", t_5);
}
output += "\r\n";
var t_6;
t_6 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "alertListService")),"getAlertTitleUrl");
frame.set("getAlertTitleUrl", t_6, true);
if(frame.topLevel) {
context.setVariable("getAlertTitleUrl", t_6);
}
if(frame.topLevel) {
context.addExport("getAlertTitleUrl", t_6);
}
output += "\r\n";
var t_7;
t_7 = (lineno = 8, colno = 56, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "alertListService")),"getFilterProperties"), "alertListService[\"getFilterProperties\"]", context, []));
frame.set("filterProp", t_7, true);
if(frame.topLevel) {
context.setVariable("filterProp", t_7);
}
if(frame.topLevel) {
context.addExport("filterProp", t_7);
}
output += "   ";
output += "\r\n";
var t_8;
t_8 = (lineno = 9, colno = 30, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "filterProp")),"getKey"), "filterProp[\"getKey\"]", context, []));
frame.set("key", t_8, true);
if(frame.topLevel) {
context.setVariable("key", t_8);
}
if(frame.topLevel) {
context.addExport("key", t_8);
}
output += "                             ";
output += "\r\n";
var t_9;
t_9 = (lineno = 10, colno = 34, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "filterProp")),"getValue"), "filterProp[\"getValue\"]", context, []));
frame.set("value", t_9, true);
if(frame.topLevel) {
context.setVariable("value", t_9);
}
if(frame.topLevel) {
context.addExport("value", t_9);
}
output += "                         ";
output += "\r\n";
var t_10;
t_10 = (lineno = 11, colno = 42, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "alertListService")),"getAlerts"), "alertListService[\"getAlerts\"]", context, []));
frame.set("alerts", t_10, true);
if(frame.topLevel) {
context.setVariable("alerts", t_10);
}
if(frame.topLevel) {
context.addExport("alerts", t_10);
}
output += "\r\n\r\n<div class=\"alerts\">\r\n    ";
if((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "alerts")),"length") === 0)) {
output += "\r\n        <div class=\"no-alert\">";
output += runtime.suppressValue((lineno = 15, colno = 67, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "alertListService")),"getMessageNoAlert"), "alertListService[\"getMessageNoAlert\"]", context, [])), env.opts.autoescape);
output += "</div>\r\n    ";
;
}
output += "\r\n    ";
frame = frame.push();
var t_13 = runtime.contextOrFrameLookup(context, frame, "alerts");
if(t_13) {t_13 = runtime.fromIterator(t_13);
var t_12 = t_13.length;
for(var t_11=0; t_11 < t_13.length; t_11++) {
var t_14 = t_13[t_11];
frame.set("alert", t_14);
frame.set("loop.index", t_11 + 1);
frame.set("loop.index0", t_11);
frame.set("loop.revindex", t_12 - t_11);
frame.set("loop.revindex0", t_12 - t_11 - 1);
frame.set("loop.first", t_11 === 0);
frame.set("loop.last", t_11 === t_12 - 1);
frame.set("loop.length", t_12);
output += "  ";
output += "\r\n        ";
var t_15;
t_15 = (lineno = 18, colno = 38, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "getAlertTitleUrl"), "getAlertTitleUrl", context, [t_14]));
frame.set("href", t_15, true);
if(frame.topLevel) {
context.setVariable("href", t_15);
}
if(frame.topLevel) {
context.addExport("href", t_15);
}
output += "\r\n        ";
var t_16;
t_16 = (lineno = 19, colno = 58, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "alertListService")),"getRoutesForAlert"), "alertListService[\"getRoutesForAlert\"]", context, [t_14]));
frame.set("routes", t_16, true);
if(frame.topLevel) {
context.setVariable("routes", t_16);
}
if(frame.topLevel) {
context.addExport("routes", t_16);
}
output += "\r\n        ";
output += runtime.suppressValue((lineno = 20, colno = 33, runtime.callWrap(runtime.memberLookup((t_1),runtime.contextOrFrameLookup(context, frame, "alertMacroName")), "macros[\"alertMacroName\"]", context, [t_14,runtime.contextOrFrameLookup(context, frame, "href"),runtime.contextOrFrameLookup(context, frame, "routeMacroName"),runtime.contextOrFrameLookup(context, frame, "routes"),t_1,runtime.contextOrFrameLookup(context, frame, "alertListService")])), env.opts.autoescape);
output += "\r\n        <hr>\r\n    ";
;
}
}
frame = frame.pop();
output += "\r\n</div>\r\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["../../../widget-alerts/src/templates/alertlist2.njk"] , dependencies)