

export class Gis{
    constructor(){}
    static computeBounds(pois){
        var east,
            west, 
            north,
            south
        ; 
        pois.forEach(function(poi){
            if (!north || (poi.lat > north)) north = poi.lat;
            if (!south || (poi.lat < south)) south = poi.lat; 
            if (!east ||  (poi.lon > east )) east = poi.lon;
            if (!west || (poi.lon < west )) west = poi.lon;
        });
        return {
            east, west, north, south
        }        
    }
}