import {serializr, indirectReference, classConstructorParam, indirectObject, funct } from '@mecatran/serializr-extensions';
import * as model from "./model.mjs";



export {serializr};
export var configModel = model;


// =========
// variables
// --------- 
var {   createModelSchema, 
    createSimpleSchema, 
    primitive, 
    reference, 
    date, raw, list, object, 
    optional, alias,
    identifier, map, mapAsArray , 
    custom, 
    getDefaultModelSchema, 
    setDefaultModelSchema,
    serialize, 
    deserialize } = serializr;

  
function factory(clazz ){
    return function(){
        return new clazz();
    }
}


export var schemaUrbiplanApiAlertConfig = {  // TMP To be replaced by a shema in the urbiplanApiConfig 
    factory : factory(model.UrbiplanApiAlertConfig),
    props : {
        instance: primitive(),
        env: primitive(),
        key : primitive(),
        preferredLang : primitive()
    }
}






// ============================

function classIdentifier(provider){
    console.log('provider',provider)
    if (provider === 'urbiplan') return model.UrbiplanApiAlertConfig;
}


var schemaConfig = {
    factory : factory(model.Config),
    props : {
        alertProviders : list(object(model.AlertProvider)),
        defaultAlertProviderConfigName : true, 
        routerConfigs : list(object(model.RouterConfig)),
        href : object(model.HRef),
        options : object(model.Options)
    }
}

var schemaAlertProvider = {
    factory : factory(model.AlertProvider),
    props : {
        provider : true,
        name : true, 
        config : indirectObject(classIdentifier, 'provider') 
    }
}

var schemaRouterConfig = {
    factory : factory(model.RouterConfig),
    props : {
        test : funct(),
        viewConfigName : true 
    }
}

var schemaHRefConfig = {
    factory : factory(model.HRef),
    props : {
        computeTitleUrl : funct(), 
        computeAlertTitleUrl : funct()
    }
}

var schemaOptionsConfig = {
    factory : factory(model.Options),
    props : {
        hasSelector : true,
        alertSorterType : classConstructorParam(model.AlertSorterType)
    }
}

var schemaAlertSorterType = {
    factory : factory(model.AlertSorterType),
    props : {}
}



setDefaultModelSchema(model.UrbiplanApiAlertConfig, schemaUrbiplanApiAlertConfig)
setDefaultModelSchema(model.AlertProvider, schemaAlertProvider);
setDefaultModelSchema(model.Config, schemaConfig);
setDefaultModelSchema(model.RouterConfig, schemaRouterConfig);
setDefaultModelSchema(model.HRef, schemaHRefConfig );
setDefaultModelSchema(model.Options, schemaOptionsConfig);
setDefaultModelSchema(model.AlertSorterType, schemaAlertSorterType)



